import React, { useContext } from 'react'

import { useScopedIntl } from '../../../../hooks'
import { AclAction, AclFeature } from '../../../../requests'
import { UserContext } from '../../../auth'
import { DatacBulkActionsBar } from '../../../common'
import { useSubjectRepositoryDashboardStore } from '../SubjectRepositoryDashboardStore'

export const SubjectRepositoryOptions: React.VFC = () => {
  const intl = useScopedIntl('')
  const intlRepositoryTable = useScopedIntl('subject_repository.table')
  const { user } = useContext(UserContext)
  const userCan = user.canDo(AclFeature.SubjectRepository)
  const {
    setIsExportModalOpened,
    setIsMessageModalOpened,
    setIsResendModalOpened,
    setIsAccountModalOpened,
    setIsAssignToRecruitmentModalOpened,
    setSelectedSubjects,
    setIsEverythingSelected,
    selectedSubjects,
    isEverythingSelected,
    allSubjectsCount
  } = useSubjectRepositoryDashboardStore()

  const selectedCount = isEverythingSelected ? allSubjectsCount : selectedSubjects.length

  return (
    <>
      <DatacBulkActionsBar
        selectedCount={selectedCount}
        onClose={() => {
          setSelectedSubjects([])
          setIsEverythingSelected(false)
        }}
        actions={[
          {
            label: intl('common.resend'),
            icon: 'repeat',
            onClick: () => setIsResendModalOpened(true)
          },
          {
            label: intl('common.account_creation'),
            icon: 'tablet',
            onClick: () => setIsAccountModalOpened(true)
          },
          {
            label: intl('common.export'),
            icon: 'download',
            onClick: () => setIsExportModalOpened(true),
            hidden: !userCan(AclAction.Export)
          },
          {
            label: intlRepositoryTable('message'),
            icon: 'mail',
            onClick: () => setIsMessageModalOpened(true),
            hidden: !user.canDo(AclFeature.SubjectRepositoryCampaign)(AclAction.Add)
          },
          {
            label: intlRepositoryTable('assign_to_recruitment'),
            icon: 'filePlus',
            onClick: () => setIsAssignToRecruitmentModalOpened(true),
            hidden:
              !user.canDo(AclFeature.RecruitmentRecords)(AclAction.Invite) || !user.canAccess(AclFeature.Recruitment)
          }
        ]}
      />
    </>
  )
}
