import './MessageModal.less'

import { Form, Input, Radio, Space } from 'antd'
import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../hooks'
import { MessageType, SendRepositoryMessageOptions, sendRepositoryMessage } from '../../../../requests'
import { maxTitleLengthLong, validateLink, validateRequired } from '../../../../validation'
import {
  AdvancedFiltersToConditions,
  DatacFormItem,
  DatacInformationMessage,
  DatacInstructionTooltip,
  DatacMessage,
  DatacModal
} from '../../../common'
import { EditorInput } from '../../../shared/Editor'
import { useSubjectRepositoryDashboardStore } from '../SubjectRepositoryDashboardStore'

const profileVariables = ['datacapt_id', 'first_name', 'last_name', 'email', 'phone', 'language', 'creation_date']

interface Props {
  subjectsWithEmailCount: number
  subjectsWithPhoneCount: number
  variables: string[]
}

export const MessageModal: React.VFC<Props> = ({ subjectsWithEmailCount, subjectsWithPhoneCount, variables }) => {
  const intlModal = useScopedIntl('subject_repository.message.modal')
  const intl = useScopedIntl('')
  const [formInstance] = Form.useForm()
  const [messageType, setMessageType] = useState(MessageType.Email)
  const [subjectsCount, setSubjectsCount] = useState(subjectsWithEmailCount)

  const {
    selectedSubjects,
    search,
    filters,
    isMessageModalOpened,
    setIsMessageModalOpened,
    isEverythingSelected,
    getParsedStatusFilter
  } = useSubjectRepositoryDashboardStore()

  useEffect(() => {
    if (isMessageModalOpened) {
      setMessageType(MessageType.Email)
      formInstance.resetFields()
    }
  }, [isMessageModalOpened, formInstance])

  useEffect(() => {
    if (messageType === MessageType.Sms) {
      setSubjectsCount(subjectsWithPhoneCount)
      return
    }
    setSubjectsCount(subjectsWithEmailCount)
  }, [messageType, subjectsWithEmailCount, subjectsWithPhoneCount])

  const onSendMessage = (options: SendRepositoryMessageOptions) => {
    sendRepositoryMessage(
      {
        ...options,
        search,
        conditions: AdvancedFiltersToConditions(filters),
        status: getParsedStatusFilter(),
        subjects: isEverythingSelected ? undefined : selectedSubjects
      },
      {
        onSuccess: () => setIsMessageModalOpened(false),
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }

  const messageTypeOptions = [
    { label: intl('common.message_type.email'), value: MessageType.Email },
    { label: intl('common.message_type.sms'), value: MessageType.Sms }
  ]

  return (
    <DatacModal
      title={intlModal('title')}
      isOpened={isMessageModalOpened}
      onClose={() => setIsMessageModalOpened(false)}
      onSubmit={() => formInstance.submit()}
      destroyOnClose
    >
      <div className="subject-repository-message-modal__body">
        <DatacInformationMessage message={intlModal('subject_count_info', { count: subjectsCount })} />
        <Form form={formInstance} onFinish={onSendMessage} name="subject-repository-message-modal-form">
          <DatacFormItem
            name="messageType"
            className="subject-repository-message-modal__type"
            label={intlModal('message_type.label')}
            initialValue={messageType}
          >
            <Radio.Group
              options={messageTypeOptions}
              value={messageType}
              onChange={e => setMessageType(e.target.value)}
            />
          </DatacFormItem>
          {messageType === MessageType.Email && (
            <>
              <DatacFormItem
                name="emailSubject"
                label={intlModal('email_subject.label')}
                validate={validateRequired(intl('common.required'))}
              >
                <Input
                  size="large"
                  maxLength={maxTitleLengthLong}
                  placeholder={intlModal('email_subject.placeholder')}
                />
              </DatacFormItem>
              <EditorInput
                fieldName="emailBody"
                label={intlModal('email_body.label')}
                initialValue=""
                placeToolbarOnBottom
                variables={[...profileVariables, ...variables]}
              />
            </>
          )}
          {messageType === MessageType.Sms && (
            <>
              <DatacFormItem
                name="smsBody"
                label={intlModal('sms_body.label')}
                validate={validateRequired(intl('common.required'))}
              >
                <Input.TextArea maxLength={130} rows={2} placeholder={intlModal('sms_body.placeholder')} />
              </DatacFormItem>
              <DatacFormItem
                name="smsLink"
                label={
                  <Space>
                    {intlModal('sms_link.label')}
                    <DatacInstructionTooltip instruction={intlModal('sms_link.instructions')} />
                  </Space>
                }
                validate={[validateLink(intlModal('sms_link.validation.wrong'))]}
              >
                <Input size="large" placeholder={intlModal('sms_link.placeholder')} maxLength={1000} />
              </DatacFormItem>
            </>
          )}
        </Form>
      </div>
    </DatacModal>
  )
}
