import { create } from 'zustand'

import {
  ConditionalLogicOperator,
  SelectionKeys,
  SubjectInRepository,
  SubjectInRepositorySorter,
  SubjectRepositoryStats,
  SubjectStatus,
  TableVariable
} from '../../../requests'
import { TableRecord } from '../../../utils'
import { AdvancedFilter, DatacOption } from '../../common'

interface onSubjectsPageSuccessParams {
  subjects: TableRecord<SubjectInRepository>[]
  allSubjectsCount: number
  subjectsWithEmailCount: number
  subjectsWithPhoneCount: number
  activeSubjectsWithEmailCount: number
  activeSubjectsWithPhoneCount: number
  subjectsWithoutAccountCount: number
  variables: Record<string, string>
  currentPage: number
}

interface SubjectRepositoryDashboardState {
  isNewSubjectModalOpened: boolean
  isFetchingSubjects: boolean
  isFetchingVariables: boolean
  isSearching: boolean
  isCustomizeTableModalOpened: boolean
  isExportModalOpened: boolean
  isImportModalOpened: boolean
  isMessageModalOpened: boolean
  isResendModalOpened: boolean
  isAccountModalOpened: boolean
  isAssignToRecruitmentModalOpened: boolean
  sorter: SubjectInRepositorySorter
  selectedSubjects: SelectionKeys
  stats: SubjectRepositoryStats
  variables: Record<string, string>
  allVariables: TableVariable[]
  subjects: TableRecord<SubjectInRepository>[]
  allSubjectsCount: number
  subjectsWithEmailCount: number
  subjectsWithPhoneCount: number
  activeSubjectsWithEmailCount: number
  activeSubjectsWithPhoneCount: number
  subjectsWithoutAccountCount: number
  subjectToView: SubjectInRepository
  centersOptions: DatacOption[]
  currentPage: number
  search: string
  filters: AdvancedFilter[]
  status: { value: SubjectStatus[]; operator: ConditionalLogicOperator }
  setIsNewSubjectModalOpened: (isNewSubjectModalOpened: boolean) => void
  setIsFetchingSubjects: (isFetchingSubjects: boolean) => void
  setIsFetchingVariables: (isFetchingVariables: boolean) => void
  setIsCustomizeTableModalOpened: (isCustomizeTableModalOpened: boolean) => void
  setIsExportModalOpened: (isExportModalOpened: boolean) => void
  setIsImportModalOpened: (isImportModalOpened: boolean) => void
  setIsMessageModalOpened: (isMessageModalOpened: boolean) => void
  setIsResendModalOpened: (isResendModalOpened: boolean) => void
  setIsAccountModalOpened: (isAccountModalOpened: boolean) => void
  setIsAssignToRecruitmentModalOpened: (isAssignToRecruitmentModalOpened: boolean) => void
  setSorter: (sorter: SubjectInRepositorySorter) => void
  setSelectedSubjects: (selectedSubjects: SelectionKeys) => void
  setStats: (stats: SubjectRepositoryStats) => void
  setAllVariables: (allVariables: TableVariable[]) => void
  onSubjectsPageSuccess: (params: onSubjectsPageSuccessParams) => void
  setSubjectToView: (subjectToView: SubjectInRepository) => void
  setCentersOptions: (centersOptions: DatacOption[]) => void
  setSearch: (search: string) => void
  setFilters: (filters: AdvancedFilter[]) => void
  setStatus: (status: { value: SubjectStatus[]; operator: ConditionalLogicOperator }) => void
  isEverythingSelected: boolean
  setIsEverythingSelected: (isEverythingSelected: boolean) => void
  getParsedStatusFilter: () => {
    selections: SubjectStatus[]
    operator: ConditionalLogicOperator
  }
}

export const useSubjectRepositoryDashboardStore = create<SubjectRepositoryDashboardState>()((set, get) => ({
  isNewSubjectModalOpened: false,
  isFetchingSubjects: false,
  isFetchingVariables: false,
  isSearching: false,
  isCustomizeTableModalOpened: false,
  isExportModalOpened: false,
  isImportModalOpened: false,
  isMessageModalOpened: false,
  isResendModalOpened: false,
  isAccountModalOpened: false,
  isAssignToRecruitmentModalOpened: false,
  sorter: null,
  selectedSubjects: [],
  stats: null,
  variables: {},
  allVariables: [],
  subjects: [],
  allSubjectsCount: 0,
  subjectsWithEmailCount: 0,
  subjectsWithPhoneCount: 0,
  activeSubjectsWithEmailCount: 0,
  activeSubjectsWithPhoneCount: 0,
  subjectsWithoutAccountCount: 0,
  subjectToView: null,
  centersOptions: [],
  currentPage: 1,
  search: '',
  filters: [],
  status: null,
  setIsNewSubjectModalOpened: (isNewSubjectModalOpened: boolean) => set({ isNewSubjectModalOpened }),
  setIsFetchingSubjects: (isFetchingSubjects: boolean) => set({ isFetchingSubjects }),
  setIsFetchingVariables: (isFetchingVariables: boolean) => set({ isFetchingVariables }),
  setIsSearching: (isSearching: boolean) => set({ isSearching }),
  setIsCustomizeTableModalOpened: (isCustomizeTableModalOpened: boolean) => set({ isCustomizeTableModalOpened }),
  setIsExportModalOpened: (isExportModalOpened: boolean) => set({ isExportModalOpened }),
  setIsImportModalOpened: (isImportModalOpened: boolean) => set({ isImportModalOpened }),
  setIsMessageModalOpened: (isMessageModalOpened: boolean) => set({ isMessageModalOpened }),
  setIsResendModalOpened: (isResendModalOpened: boolean) => set({ isResendModalOpened }),
  setIsAccountModalOpened: (isAccountModalOpened: boolean) => set({ isAccountModalOpened }),
  setIsAssignToRecruitmentModalOpened: (isAssignToRecruitmentModalOpened: boolean) =>
    set({ isAssignToRecruitmentModalOpened }),
  setSorter: (sorter: SubjectInRepositorySorter) => set({ sorter }),
  setSelectedSubjects: (selectedSubjects: SelectionKeys) => set({ selectedSubjects }),
  setStats: (stats: SubjectRepositoryStats) => set({ stats }),
  setAllVariables: (allVariables: TableVariable[]) => set({ allVariables }),
  onSubjectsPageSuccess: ({
    subjects,
    allSubjectsCount,
    subjectsWithEmailCount,
    subjectsWithPhoneCount,
    activeSubjectsWithEmailCount,
    activeSubjectsWithPhoneCount,
    subjectsWithoutAccountCount,
    variables,
    currentPage
  }: onSubjectsPageSuccessParams) =>
    set({
      subjects,
      allSubjectsCount,
      subjectsWithEmailCount,
      subjectsWithPhoneCount,
      activeSubjectsWithEmailCount,
      activeSubjectsWithPhoneCount,
      subjectsWithoutAccountCount,
      variables,
      currentPage,
      isFetchingSubjects: false,
      isSearching: false
    }),
  setSubjectToView: (subjectToView: SubjectInRepository) => set({ subjectToView }),
  setCentersOptions: (centersOptions: DatacOption[]) => set({ centersOptions }),
  setSearch: (search: string) => set({ search, isSearching: true }),
  setFilters: (filters: AdvancedFilter[]) => set({ filters }),
  setStatus: (status: { value: SubjectStatus[]; operator: ConditionalLogicOperator }) => set({ status }),
  isEverythingSelected: false,
  setIsEverythingSelected: (isEverythingSelected: boolean) => set({ isEverythingSelected }),
  getParsedStatusFilter: () => {
    const statusFilter = get().status
    if (!statusFilter?.value?.length) return null
    return {
      selections: statusFilter.value,
      operator: statusFilter.operator
    }
  }
}))
