import { RouteComponentProps, Router } from '@gatsbyjs/reach-router'
import React, { useContext } from 'react'

import { AclAction, AclFeature } from '../../requests'
import { RedirectNoAccessWrapper } from '../RedirectNoAccessWrapper'
import { UserContext } from '../auth'
import { SubjectRepositoryBuilderContent } from './SubjectRepositoryBuilderContent'
import { SubjectRepositoryDashboard } from './SubjectRepositoryDashboard'
import { SubjectRepositoryProfile } from './SubjectRepositoryProfile'
import { SubjectRepositoryQrCodes } from './SubjectRepositoryQrCodes'
import { SubjectRepositorySettings } from './SubjectRepositorySettings'

export const SubjectRepository: React.FC<RouteComponentProps> = () => {
  const { user } = useContext(UserContext)
  const userCan = user.canDo(AclFeature.SubjectRepository)

  return (
    <Router>
      <RedirectNoAccessWrapper
        hasNoAccess={!user.canAccess(AclFeature.SubjectRepository) || !user.isSubjectRepositoryEnabled}
        path="/"
      >
        <SubjectRepositoryDashboard path="/" />
      </RedirectNoAccessWrapper>
      <RedirectNoAccessWrapper
        hasNoAccess={!user.canAccess(AclFeature.SubjectRepositoryBuilder) || !user.isSubjectRepositoryEnabled}
        path="builder/*"
      >
        <SubjectRepositoryBuilderContent path="builder/*" />
      </RedirectNoAccessWrapper>
      <RedirectNoAccessWrapper
        hasNoAccess={!userCan(AclAction.BasicSettings) || !user.isSubjectRepositoryEnabled}
        path="qr-codes/*"
      >
        <SubjectRepositoryQrCodes path="qr-codes/*" />
      </RedirectNoAccessWrapper>
      <RedirectNoAccessWrapper
        hasNoAccess={!user.canAccess(AclFeature.SubjectRepository) || !user.isSubjectRepositoryEnabled}
        path="settings/*"
      >
        <SubjectRepositorySettings path="settings/*" />
      </RedirectNoAccessWrapper>
      <RedirectNoAccessWrapper
        hasNoAccess={!user.canAccess(AclFeature.SubjectRepository) || !user.isSubjectRepositoryEnabled}
        path="profile/*"
      >
        <SubjectRepositoryProfile path="profile/*" />
      </RedirectNoAccessWrapper>
    </Router>
  )
}
