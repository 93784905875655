import { DragEndEvent } from '@dnd-kit/core'
import { useMatch } from '@gatsbyjs/reach-router'
import { navigate } from 'gatsby-plugin-react-intl'
import React, { useCallback, useReducer, useState } from 'react'

import {
  Block,
  DeleteBlockResponseHandlers,
  Feature,
  FetchSectionResponseHandlers,
  FetchStructureResponseHandlers,
  QuestionType,
  SaveBlockResponseHandlers,
  SearchQuestionsResponseHandlers,
  StaticContentType,
  Structure,
  UpdateSectionOptions,
  UpdateSectionResponseHandlers,
  UpdateStructureResponseHandlers,
  UpdateSubsectionOptions,
  UpdateSubsectionResponseHandlers,
  deleteBlock as deleteBlockRequest,
  fetchSubjectRepositoryQuestionsDependencies,
  fetchSubjectRepositorySection,
  fetchSubjectRepositoryStructure,
  saveBlock as saveBlockRequest,
  searchSubjectRepositoryQuestions,
  updateSubjectRepositorySection,
  updateSubjectRepositoryStructure,
  updateSubjectRepositorySubsection
} from '../../../requests'
import { routes } from '../../../routes'
import { getConditionalQuestionById } from '../../../utils'
import {
  Builder,
  BuilderAction,
  BuilderContext,
  BuilderProps,
  BuilderTab,
  builderInitialState,
  builderStateReducer
} from '../../shared/Builder'

const availableQuestionTypes = new Set([
  QuestionType.Text,
  QuestionType.Checkbox,
  QuestionType.Radio,
  QuestionType.Dropdown,
  QuestionType.DateTime,
  QuestionType.Number,
  QuestionType.Rating,
  QuestionType.File,
  QuestionType.Slider,
  QuestionType.Calcul
])

const availableQuestionTabs = new Set([BuilderTab.Edit, BuilderTab.Validation, BuilderTab.Conditions])

const SubjectRepositoryBuilderWrapper: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const urlParams = useMatch(`/:lang${routes.subjectRepositoryBuilderSection()}`)
  const [builderState, builderDispatch] = useReducer(builderStateReducer, builderInitialState)
  const studyId: string = null
  const [sortEvent, setSortEvent] = useState<DragEndEvent>(null)

  const updateQuestionsDependencies = () => {
    fetchSubjectRepositoryQuestionsDependencies(
      { studyId },
      {
        onSuccess: ({ conditionalQuestions, dateTimes, invalidBlocks, referenceQuestionsIds }) => {
          builderDispatch({
            type: BuilderAction.SET_QUESTION_DEPENDENCIES,
            conditionalQuestions,
            dateTimeQuestions: dateTimes,
            questionsFromStudy: [],
            invalidBlocks,
            referenceQuestionsIds
          })
        }
      }
    )
  }

  const fetchSection = useCallback(
    ({ sectionId }: { sectionId: string }, responseHandlers?: FetchSectionResponseHandlers) => {
      if (!sectionId) return null
      return fetchSubjectRepositorySection({ studyId, sectionId }, responseHandlers)
    },
    []
  )

  const fetchStructure = useCallback((responseHandlers?: FetchStructureResponseHandlers) => {
    return fetchSubjectRepositoryStructure({ studyId }, responseHandlers)
  }, [])

  const updateStructure = useCallback(
    ({ structure }: { structure: Structure }, responseHandlers?: UpdateStructureResponseHandlers) => {
      return updateSubjectRepositoryStructure({ structure, studyId }, responseHandlers)
    },
    []
  )

  const updateSection = useCallback(
    (options: UpdateSectionOptions, responseHandlers?: UpdateSectionResponseHandlers) => {
      return updateSubjectRepositorySection({ studyId, ...options }, responseHandlers)
    },
    []
  )

  const updateSubsection = useCallback(
    (options: UpdateSubsectionOptions, responseHandlers?: UpdateSubsectionResponseHandlers) => {
      return updateSubjectRepositorySubsection({ studyId, ...options }, responseHandlers)
    },
    []
  )

  const saveBlock = useCallback(
    (
      {
        sectionId,
        subsectionId,
        order,
        block
      }: { sectionId: string; subsectionId: string; order: number; block: Block },
      responseHandlers?: SaveBlockResponseHandlers
    ) => {
      return saveBlockRequest(
        { target: Feature.SubjectRepository, sectionId, subsectionId, order, block, studyId },
        responseHandlers
      )
    },
    []
  )

  const deleteBlock = useCallback(
    (
      { sectionId, subsectionId, blockId }: { sectionId: string; subsectionId: string; blockId: string },
      responseHandlers?: DeleteBlockResponseHandlers
    ) => {
      return deleteBlockRequest(
        { target: Feature.SubjectRepository, sectionId, subsectionId, blockId, studyId },
        responseHandlers
      )
    },
    []
  )

  const sectionRoute = useCallback((sectionId: string) => routes.subjectRepositoryBuilderSection(sectionId), [])

  const searchQuestions = useCallback(
    ({ search }: { search: string }, responseHandlers?: SearchQuestionsResponseHandlers) => {
      return searchSubjectRepositoryQuestions({ studyId, search }, responseHandlers)
    },
    []
  )

  const goToStructureBuilder = useCallback(() => {
    navigate(routes.subjectRepositoryBuilderStructure)
  }, [])

  return (
    <BuilderContext.Consumer>
      {() => (
        <BuilderContext.Provider
          value={{
            ...builderState,
            builderDispatch,
            target: Feature.SubjectRepository,
            updateQuestionsDependencies,
            getConditionalQuestionById: (questionId: string) =>
              getConditionalQuestionById(builderState.conditionalQuestions, questionId),
            sectionId: urlParams?.sectionId,
            fetchSection,
            fetchStructure,
            updateStructure,
            updateSection,
            updateSubsection,
            createFromStructure: updateStructure,
            saveBlock,
            deleteBlock,
            goToStructureBuilder,
            availableQuestionTabs,
            availableQuestionTypes,
            availableStaticContentTypes: new Set(Object.values(StaticContentType)),
            sectionRoute,
            searchQuestions,
            sortEvent,
            setSortEvent
          }}
        >
          {children}
        </BuilderContext.Provider>
      )}
    </BuilderContext.Consumer>
  )
}

export const SubjectRepositoryBuilder: React.FC<BuilderProps> = props => (
  <SubjectRepositoryBuilderWrapper>
    <Builder {...props} />
  </SubjectRepositoryBuilderWrapper>
)
