import './SubjectRepositoryQrCodes.less'

import { RouteComponentProps } from '@gatsbyjs/reach-router'
import { navigate } from 'gatsby-plugin-react-intl'
import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../hooks'
import { fetchCenters } from '../../../requests'
import { routes } from '../../../routes'
import { DatacBox, DatacIcon, DatacMessage, DatacQrCodes, DatacTitle } from '../../common'

const pageSize = 25

interface SubjectRepositoryQrCode {
  center: string
  token: string
}

export const SubjectRepositoryQrCodes: React.FC<RouteComponentProps> = () => {
  const intl = useScopedIntl('')
  const intlSettings = useScopedIntl('subject_repository.settings')

  const [qrCodes, setQrCodes] = useState<SubjectRepositoryQrCode[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [allCount, setAllCount] = useState(0)
  const [currentPageSize, setCurrentPageSize] = useState(pageSize)

  useEffect(() => {
    fetchQrCodesPage(0)
  }, [currentPageSize])

  const fetchQrCodesPage = (page: number) => {
    return fetchCenters(
      {
        assignedToUser: true,
        options: {
          limit: currentPageSize,
          offset: page * currentPageSize
        }
      },
      {
        onSuccess: ({ centers, allCentersCount }) => {
          setQrCodes(centers.map(center => ({ center: center.name, token: center.token })))
          setAllCount(allCentersCount)
          setCurrentPage(page + 1)
        },
        onRequestError: code => {
          DatacMessage.genericError(intl, code)
        }
      }
    )
  }

  const onPageChange = (page: number) => {
    fetchQrCodesPage(page - 1)
  }

  return (
    <div className="subject-repository-qrcodes">
      <div className="subject-repository-qrcodes__header">
        <div onClick={() => navigate(routes.subjectRepositorySettings)} className="subject-repository-qrcodes__go-back">
          <DatacIcon name="arrowLeft" type="blue" size="small" />
          {intlSettings('back')}
        </div>
        <div className="recruitment-study-details-layout__header__name">
          <DatacTitle type="h1">{intlSettings('qr_codes_title')}</DatacTitle>
        </div>
      </div>
      <DatacBox className="subject-repository-qrcodes__list" hasBorder={false}>
        <DatacQrCodes
          qrCodes={qrCodes.map(({ center, token }) => ({ name: center, token }))}
          urlSplitSeparator="/subject-database/"
          route={routes.subjectRepositorySignup}
          pagination={{
            current: currentPage,
            onChange: onPageChange,
            total: allCount,
            pageSize: currentPageSize
          }}
          setPageSize={setCurrentPageSize}
        />
      </DatacBox>
    </div>
  )
}
