import './SubjectRepositoryStats.less'

import { Progress } from 'antd'
import classNames from 'classnames'
import React from 'react'
import { Scrollbar } from 'react-scrollbars-custom'

import { useScopedIntl } from '../../../../hooks'
import { SubjectStatus } from '../../../../requests'
import { DatacBox, DatacRecordStatusTag, DatacTitle } from '../../../common'
import { useSubjectRepositoryDashboardStore } from '../SubjectRepositoryDashboardStore'

const SubjectRepositoryStatsPercent: React.VFC<{ count: number; showBar?: boolean }> = ({ count, showBar }) => {
  const { stats } = useSubjectRepositoryDashboardStore()
  const intlStats = useScopedIntl('subject_repository.stats')
  const compare = showBar ? stats.all : stats.displayed
  const percent = compare ? Math.round((100 * count) / compare) : null
  const label = stats.displayed !== stats.all && !showBar ? 'of_matching' : 'of_total'

  return (
    <>
      {showBar && <Progress status="normal" className="ant-progress-completed" percent={percent} showInfo={false} />}
      {percent != null && (
        <div className="subject-repository-stats__percent">
          <span>{percent}%</span> {intlStats(label)}
        </div>
      )}
    </>
  )
}

const SubjectRepositoryStatsBox: React.VFC<{ type: SubjectStatus }> = ({ type }) => {
  const { stats } = useSubjectRepositoryDashboardStore()

  const getValue = () => {
    switch (type) {
      case SubjectStatus.Inactive:
        return stats.inactive
      case SubjectStatus.Pending:
        return stats.pending
      case SubjectStatus.DeletionRequested:
        return stats.deletionRequested
      default:
        return stats.active
    }
  }

  return (
    <div className="subject-repository-stats__box">
      <DatacRecordStatusTag status={type} coloredBackground />
      <DatacTitle>{getValue()}</DatacTitle>
      <SubjectRepositoryStatsPercent count={getValue()} />
    </div>
  )
}

export const SubjectRepositoryStats: React.VFC = () => {
  const intlStats = useScopedIntl('subject_repository.stats')
  const { stats } = useSubjectRepositoryDashboardStore()

  if (!stats?.all) return null

  return (
    <Scrollbar
      className="subject-repository-stats__scroll"
      noScrollY
      disableTrackXWidthCompensation
      style={{ width: '100%', height: '13rem' }}
    >
      <DatacBox className="subject-repository-stats">
        <div className="subject-repository-stats__all">
          <div className="subject-repository-stats__all__title">{intlStats('total')}</div>
          <DatacTitle>{stats.all || 0}</DatacTitle>
          <div className="subject-repository-stats__all__last-days">
            <span
              className={classNames({
                'subject-repository-stats__all__last-days__green': stats.lastDays > 0,
                'subject-repository-stats__all__last-days__red': stats.lastDays < 0
              })}
            >
              {stats.lastDays >= 0 && '+'}
              {stats.lastDays}
            </span>{' '}
            {intlStats('last_days')}
          </div>
        </div>
        <div className="subject-repository-stats__displayed">
          <div className="subject-repository-stats__displayed__number">
            <span>{stats.displayed || 0}</span> {intlStats('displayed')}
          </div>
          <SubjectRepositoryStatsPercent count={stats.displayed} showBar />
        </div>
        <SubjectRepositoryStatsBox type={SubjectStatus.Active} />
        <SubjectRepositoryStatsBox type={SubjectStatus.Inactive} />
        <SubjectRepositoryStatsBox type={SubjectStatus.Pending} />
        <SubjectRepositoryStatsBox type={SubjectStatus.DeletionRequested} />
      </DatacBox>
    </Scrollbar>
  )
}
