import './AccountModal.less'

import React, { useCallback } from 'react'

import { useScopedIntl } from '../../../../hooks'
import { BackendError, createAccounts } from '../../../../requests'
import { AdvancedFiltersToConditions, DatacInformationMessage, DatacMessage, DatacModal } from '../../../common'
import { useSubjectRepositoryDashboardStore } from '../SubjectRepositoryDashboardStore'

interface Props {
  subjectsWithoutAccountCount: number
}

export const AccountModal: React.VFC<Props> = ({ subjectsWithoutAccountCount }) => {
  const intlModal = useScopedIntl('subject_repository.account.modal')
  const intl = useScopedIntl('')

  const {
    selectedSubjects,
    search,
    filters,
    isAccountModalOpened,
    setIsAccountModalOpened,
    isEverythingSelected,
    getParsedStatusFilter
  } = useSubjectRepositoryDashboardStore()

  const displayAccountCreationErrors = useCallback((errors: Record<number, number>) => {
    if (!errors) return
    if (errors[BackendError.SUBJECT_ACCOUNT_EXISTS]) {
      DatacMessage.error(
        intlModal('error.title'),
        intlModal('error.account_exists', { count: errors[BackendError.SUBJECT_ACCOUNT_EXISTS] })
      )
    }
    if (errors[BackendError.USER_WITH_THAT_EMAIL_EXISTS]) {
      DatacMessage.error(
        intlModal('error.title'),
        intlModal('error.email_exists', { count: errors[BackendError.USER_WITH_THAT_EMAIL_EXISTS] })
      )
    }
    if (errors[BackendError.SUBJECT_EMAIL_REQUIRED]) {
      DatacMessage.error(
        intlModal('error.title'),
        intlModal('error.email_required', { count: errors[BackendError.SUBJECT_EMAIL_REQUIRED] })
      )
    }
    if (errors[BackendError.SUBJECT_NAME_REQUIRED]) {
      DatacMessage.error(
        intlModal('error.title'),
        intlModal('error.name_required', { count: errors[BackendError.SUBJECT_NAME_REQUIRED] })
      )
    }
  }, [])

  const onSendMessage = () => {
    createAccounts(
      {
        search,
        conditions: AdvancedFiltersToConditions(filters),
        status: getParsedStatusFilter(),
        subjects: isEverythingSelected ? undefined : selectedSubjects
      },
      {
        onSuccess: errors => {
          setIsAccountModalOpened(false)
          displayAccountCreationErrors(errors)
        },
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }

  return (
    <DatacModal
      title={intl('common.account_creation')}
      isOpened={isAccountModalOpened}
      onClose={() => setIsAccountModalOpened(false)}
      onSubmit={() => onSendMessage()}
      destroyOnClose
    >
      <div className="subject-repository-account-modal__body">
        <DatacInformationMessage message={intlModal('subject_count_info', { count: subjectsWithoutAccountCount })} />
      </div>
    </DatacModal>
  )
}
